import axios from "@/axios";

export default {
	state: {
		classes: [],
		uploads: [],
		isLoadingStore: true,
		hierarchies: [],
		activeTab: "1"
	},
	mutations: {
		setActiveTab(state, tabkey) {
			state.activeTab = tabkey;
		},
		setClasses(state, newClasses) {
			state.classes = newClasses;
		},
		setHierarchies(state, hierarchies) {
			state.hierarchies = hierarchies;
		},
		doneLoadingStore(state) {
			state.isLoadingStore = false;
		},
		setUploadDataById(state, data) {
			const index = state.uploads.findIndex(u => u.id === data.id);
			if (index !== -1) {
				state.uploads[index] = data;
			}
		},
		setUploads(state, uploads) {
			uploads = uploads.map(record => {
				if (record.class_id === null) record.class_id = undefined;
				return record;
			});
			state.uploads = uploads;
		},
		addUploadRecord(state, uploadRecord) {
			if (uploadRecord.class_id === null)
				uploadRecord.class_id = undefined;

			state.uploads.push(uploadRecord);
		},
		removeUploadRecord(state, id) {
			state.uploads = state.uploads.filter(upload => upload.id !== id);
		}
	},
	getters: {
		getClasses(state) {
			return state.classes;
		},
		getUploads(state) {
			return state.uploads;
		},
		isLoadingStore(state) {
			return state.isLoadingStore;
		},
		getHierarchies(state) {
			return state.hierarchies;
		},
		getActiveTab(state) {
			return state.activeTab;
		}
	},
	actions: {
		async init({ dispatch, commit }) {
			await dispatch("fetchClasses");
			await dispatch("fetchUploads");

			commit("doneLoadingStore");
		},
		fetchClasses({ commit }) {
			return axios.get("/api/view/trainingdata").then(response => {
				commit("setClasses", response.data.classes);
				commit("setHierarchies", response.data.hierarchy);
			});
		},
		fetchUploads({ commit }) {
			return axios.get("/api/trainingdata").then(response => {
				commit("setUploads", response.data);
			});
		},
		addUploadData({ commit }, uploadRecord) {
			commit("addUploadRecord", uploadRecord);
		},
		async removeTrainingData({ commit }, uploadRecord) {
			const result = await axios.delete(
				`/api/trainingdata/${uploadRecord.id}`,
				{
					params: {
						upload_key: uploadRecord.upload_key
					}
				}
			);
			commit("removeUploadRecord", uploadRecord.id);
			return result;
		},
		changeTab({ commit }, newTab) {
			commit("setActiveTab", newTab);
		}
	}
};
