<template>
	<v-dialog max-width="50%" v-model="helpStatus.open">
		<v-card max-height="50vh">
			<v-app-bar flat color="primary" dark>
				<v-toolbar-title>PowerText Help</v-toolbar-title>
			</v-app-bar>

			<v-card-title>
				{{ page.title }}
			</v-card-title>

			<v-card-text>
				<div v-html="page.html"></div>
			</v-card-text>

			<v-footer class="pa-2 justify-end">
				<v-btn text @click="closeHelp">Close</v-btn>
			</v-footer>
		</v-card>
	</v-dialog>
</template>

<script>
import homeHelp from "./pages/home/home.html?raw";
import categoriesHelp from './pages/home/categories.html?raw';
import uploadDetailsHelp from './pages/home/upload_details/upload_details.html?raw';
import generationHelp from './pages/home/upload_details/generation.html?raw';
import translationHelp from './pages/home/upload_details/translation.html?raw';
import templateHelp from './pages/home/template/template.html?raw';
import classInformationHelp from './pages/home/template/class_information.html?raw';
import featuresHelp from './pages/home/template/features.html?raw';
import sectionsHelp from './pages/home/template/sections.html?raw';
import textsHelp from './pages/home/template/texts.html?raw';
import templatesHelp from './pages/home/template/templates.html?raw';
import sectionOptionsHelp from './pages/home/template/section_options.html?raw';
import uploadHelp from './pages/upload.html?raw';
import trainingHelp from './pages/training.html?raw';
import dictionariesHelp from './pages/dictionaries.html?raw';
import categoryTextsHelp from './pages/category_texts.html?raw';
import categoryTextsTrainingHelp from './pages/category_texts_training.html?raw';
import templateSettingsHelp from './pages/template_settings.html?raw';

export default {
	components: {
	},
	data() {
		return {
			helpHierarchy: [
				{ "id": 0, "title": "Help", "parent": null },
				{ "id": 100, "title": "Home", "parent": 0, "html": homeHelp },
				{ "id": 110, "title": "Categories", "parent": 100, "html": categoriesHelp },
				{ "id": 120, "title": "Upload Details", "parent": 100, "html": uploadDetailsHelp },
				{ "id": 121, "title": "Text Generation", "parent": 120, "html": generationHelp },
				{ "id": 122, "title": "Translation", "parent": 120, "html": translationHelp },
				{ "id": 130, "title": "Template Editor", "parent": 100, "html": templateHelp },
				{ "id": 131, "title": "Class Information", "parent": 130, "html": classInformationHelp },
				{ "id": 132, "title": "Features", "parent": 130, "html": featuresHelp },
				{ "id": 133, "title": "Sections", "parent": 130, "html": sectionsHelp },
				{ "id": 134, "title": "Conditional texts", "parent": 130, "html": textsHelp },
				{ "id": 135, "title": "Templates", "parent": 130, "html": templatesHelp },
				{ "id": 136, "title": "Section Options", "parent": 130, "html": sectionOptionsHelp },
				{ "id": 137, "title": "Template Settings", "parent": 140, "html": templateSettingsHelp },
				{ "id": 200, "title": "Uploads", "parent": 0, "html": uploadHelp },
				{ "id": 300, "title": "Training Data", "parent": 0, "html": trainingHelp },
				{ "id": 400, "title": "Dictionaries", "parent": 0, "html": dictionariesHelp },
				{ "id": 500, "title": "Category texts", "parent": 0, "html": categoryTextsHelp },
				{ "id": 501, "title": "Category texts trainnig", "parent": 0, "html": categoryTextsTrainingHelp },
			]
		};
	},
	methods: {
		closeHelp() {
			this.$store.dispatch("closeHelp");
		},
	},
	computed: {
		page: function () {
			return this.helpHierarchy.find(x => x.id === this.helpStatus.currentPage);
		},
		helpStatus() {
			return this.$store.getters.getHelpStatus;
		},
	}
};
</script>

<style scoped>
html {
	overflow: hidden !important;
}

.v-card {
	display: flex !important;
	flex-direction: column;
}

.v-card__text {
	flex-grow: 1;
	overflow: auto;
}
</style>
