
<v-app class="layout">
	<Navigation />
	<v-main :style="{ 'background-color': `white` }">
		<v-container class="page-wrapper">
			<router-view />
		</v-container>
	</v-main>
	<Footer />
	<Help />
</v-app>
